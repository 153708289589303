import "./App.css";
import Main from "./Main";
import "./Style/Style.css";
import "./Style/Responsive.css";

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
