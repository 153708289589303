import React, { useState } from "react";
import logo from "../Assets/Images/Logo.png";
import video1 from "../Assets/Videos/bannervideo.mp4";

const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    const video = document.getElementById("myVideo");
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="">
      <div className="container">
        <div className="row justify-content-center">
          <div className="">
            <div className="contents">
              <div className="logo mt-5">
                <img src={logo} alt="" />
              </div>
              <div className="video-head-para">
                <h4 className="mt-5">
                  YOUR CALL HAS BEEN BOOKED & SENT VIA EMAIL
                </h4>
                <h5 className="mt-3">Important: Please Watch the</h5>
                <h5>Video Below Before Our Call ⤸</h5>
              </div>
              <div className="video-1 mt-4">
                <video id="myVideo" src={video1} autoPlay />
                <span onClick={togglePlay} className="playbtn">
                  {isPlaying ? (
                    <i className="fas fa-pause"></i>
                  ) : (
                    <i className="fas fa-play"></i>
                  )}
                </span>
              </div>
              <div className="points mt-4">
                <p>
                  <i class="fa-regular fa-circle-check"></i>Lorem Ipsum is
                  simply dummy text of the
                </p>
                <p>
                  <i class="fa-regular fa-circle-check"></i>Lorem Ipsum is
                  simply dummy text of the
                </p>
                <p>
                  <i class="fa-regular fa-circle-check"></i>Lorem Ipsum is
                  simply dummy text of the
                </p>
                <p>
                  <i class="fa-regular fa-circle-check"></i>Lorem Ipsum is
                  simply dummy text of the
                </p>
              </div>
              <div className="section2">
                <h4 className="mt-5">Lorem Ipsum is simply dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and</p>
                <p>typesetting industry</p>
              </div>
              <div className="video-1 mt-4">
                <video id="myVideo" src={video1} controls autoPlay />
                {/* <span onClick={togglePlay} className="playbtn">
                  {isPlaying ? (
                    <i className="fas fa-pause"></i>
                  ) : (
                    <i className="fas fa-play"></i>
                  )}
                </span> */}
              </div>
              <div className="lastsection">
                <h4 className="mt-5">Lorem Ipsum is simply dummy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and</p>
                <p>typesetting industry</p>
              </div>
              <div className="last-video-sec pb-5">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5">
                    <div className="video-last p-left mt-4 pe-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="video-last mt-4 p-right ps-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5">
                    <div className="video-last mt-4 p-left p-left  pe-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="video-last mt-4 p-right ps-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5">
                    <div className="video-last mt-4  p-left  pe-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="video-last mt-4 p-right ps-4 ">
                      <video src={video1} controls autoPlay />
                      {/* <span onClick={togglePlay} className="playbtn">
                        {isPlaying ? (
                          <i className="fas fa-pause"></i>
                        ) : (
                          <i className="fas fa-play"></i>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
